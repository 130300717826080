<template>
  <div>
    <c-table
      ref="table"
      :title="'\'' + popupParam.requestContents + '\'에 대한 개선요청 목록'"
      tableId="imprRequest"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      selection="multiple"
      rowKey="sopImprovementId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="Excel 업로드" icon="add" @btnClicked="openExcelUploader" />
          <c-btn v-if="editable" label="개선요청 추가" icon="add" @btnClicked="addImprActionDept" />
          <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="removeImpr" />
          <c-btn 
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="POST"
            label="개선요청 저장" 
            icon="save"
            @beforeAction="saveImprRequest"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'impr-request',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          requestContents: '',
          ibmTaskTypeCd: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: '500px'
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      isSave: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.list.url
      this.saveUrl = transactionConfig.sop.ibm.improve.insert.multi.url
      // code setting
      this.$comm.uploderSetting('IBM_BEFORE').then(_result => {
        this.grid.columns = [
          {
            name: 'plantCd',
            field: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'plant',
            required: true,
          },
          // {
          //   name: 'sopCountermeasureCd',
          //   field: 'sopCountermeasureCd',
          //   label: '대책',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          //   type: 'select',
          //   comboItems: __result['SOP_COUNTERMEASURE_CD'],
          //   required: true,
          // },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:180px',
            sortable: false,
            type: 'dept',
            deptCd: 'actionDeptCd',
            required: true,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'text',
            required: true,
          },
          {
            name: 'improveRequestContents',
            field: 'improveRequestContents',
            label: '요청내용',
            align: 'left',
            style: 'width:400px',
            sortable: false,
            type: 'textarea',
            required: true,
          },
          {
            name: 'actionCompleteRequestDate',
            field: 'actionCompleteRequestDate',
            label: '조치완료 요청일',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            type: 'date',
            required: true,
          },
          // {
          //   name: 'occurCause',
          //   field: 'occurCause',
          //   label: '발생원인',
          //   align: 'left',
          //   style: 'width:400px',
          //   sortable: false,
          //   type: 'textarea',
          //   required: true,
          // },
          // {
          //   name: 'sop4mClassCd',
          //   field: 'sop4mClassCd',
          //   label: '4M',
          //   align: 'center',
          //   style: 'width:100px',
          //   sortable: false,
          //   type: 'select',
          //   comboItems: __result['SOP_4M_CLASS_CD'],
          //   required: true,
          // },
          {
            name: 'before',
            field: 'before',
            label: '개선 전 사진',
            align: 'center',
            style: 'width:200px',
            type: 'attach',
            taskClassCd: 'IBM_BEFORE',
            keyText: 'sopImprovementId',
            sortable: false,
            uploaderSetting: _result,
          },
        ]
      });
      // list setting
    },
    /* eslint-disable no-unused-vars */
    openExcelUploader() {
      this.popupOptions.title = '개선요청 엑셀 업로드'; // 개선요청 엑셀 업로드
      this.popupOptions.param = this.popupParam;
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequestUpload.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelPopup;
    },
    closeExcelPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          item.relationTableKey = this.popupParam.relationTableKey;
          item.ibmTaskTypeCd = this.popupParam.ibmTaskTypeCd;
          item.ibmStepCd = 'IS00000001';
          item.ibmClassCd = 'IC00000001';
          item.improveRequestDeptCd = this.$store.getters.user.deptCd;
          item.improveRequestDeptName = this.$store.getters.user.deptName;
          item.improveRequestUserId = this.$store.getters.user.userId;
          item.improveRequestUserName = this.$store.getters.user.userName;
          item.regUserId = this.$store.getters.user.userId;
          item.regDt = this.$comm.getToday();
          item.editFlag = 'C';
        })
        this.grid.data = this.$_.concat(this.grid.data, s_data)
      }
    },
    addImprActionDept() {
      this.popupOptions.title = '조치부서';
      this.popupOptions.param = {
        plantCd: this.$store.getters.user.plantCd
      }
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          /**
           * 2022-01-24 kdh
           * 
           * 조치부서가 동일하더라도 추가 되도록 변경
           */
          // if (this.$_.findIndex(this.grid.data, { actionDeptCd: item.deptCd }) === -1) {
          this.grid.data.push({
            plantCd: item.plantCd,
            plantName: item.plantName,
            actionDeptCd: item.deptCd,
            actionDeptName: item.deptName,
            ibmTitle: this.popupParam.requestContents + '_' + this.$comm.getToday(),
            improveRequestContents: '',
            actionCompleteRequestDate: this.$comm.getToday(),
            sopImprovementId: uid(),
            relationTableKey: this.popupParam.relationTableKey,
            ibmTaskTypeCd: this.popupParam.ibmTaskTypeCd,
            ibmStepCd: 'IS00000001',
            ibmClassCd: 'IC00000001',
            improveRequestDeptCd: this.$store.getters.user.deptCd,
            improveRequestDeptName: this.$store.getters.user.deptName,
            improveRequestUserId: this.$store.getters.user.userId,
            improveRequestUserName: this.$store.getters.user.userName,
            // sopCountermeasureCd: 'SCM0000001',
            // occurCause: '',
            // sop4mClassCd: null,
            regUserId: this.$store.getters.user.userId,
            regDt: this.$comm.getToday(),
            editFlag: 'C',
          })
          // }
        })
      }
    },
    saveImprRequest() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인', 
          message: '목록에 표시된 개선요청을 \'' + this.popupParam.requestContents + '\'항목에 대한 개선건으로 추가합니다.\n\r 저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.grid.data, item => {
              item.ibmStepCd = 'IS00000001'
            })
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(result) {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        relationTableKey: this.popupParam.relationTableKey,
        ibmTaskTypeCd: this.popupParam.ibmTaskTypeCd,
      }
      this.$http.request((_result) => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.$emit('closePopup', _result.data);
      },);
    },
    removeImpr() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.grid.data.splice(this.$_.findIndex(this.grid.data, { sopImprovementId: item.sopImprovementId }), 1)
        })
        this.$refs['table'].$refs['compo-table'].clearSelection();
        
      }
    }
  }
};
</script>
